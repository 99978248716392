<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
};
</script>

<template>
  <hr :style="{margin: `${data.marginY}px 0 !important`}">
</template>
