<script>
// import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    html() {
      return this.data.html;
    },
  },
};
</script>

<template>
  <div class="cboecomp">
    <div class="image" v-html="html" />
    <p v-if="data.caption" class="mt-3 has-text-centered is-size-8">
      {{ data.caption }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.cboecomp {
  ::v-deep iframe {
    width: 100%;
  }
}
</style>
