<script>
import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    html() {
      return DOMPurify.sanitize(this.data.text);
    },
  },
};
</script>

<template>
  <div class="content break-words" v-html="html" />
</template>
