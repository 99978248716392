<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

const icons = {
  'is-info': 'info-circle',
  'is-warning': 'exclamation-circle',
  'is-success': 'check-circle',
  'is-danger': 'times',
};

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicData() {
      const icon = icons[this.data.type];

      return { icon };
    },
  },
};
</script>

<template>
  <div
    class="cbnvcomp box media p-2 wspace-preline break-words"
    :class="data.type"
    role="alert"
  >
    <figure class="media-left">
      <b-icon :icon="dynamicData.icon" size="is-medium" />
    </figure>
    <div class="media-content has-text-left">
      <h3 v-if="data.title" class="title is-5 is-size-6-touch pt-1 mb-2 wspace-normal">
        {{ data.title }}
      </h3>
      <p class="cbnvcomp_text">{{
        data.text
      }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cbnvcomp {
  &_text {
    display: flex;
    align-items: center;
    min-height: 32px;
  }
}
</style>
