<script>
import 'highlight.js/styles/monokai.css';
import hljs from 'highlight.js';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  mounted() {
    this.$refs.code && hljs.highlightBlock(this.$refs.code);
  },
  updated() {
    this.$refs.code && hljs.highlightBlock(this.$refs.code);
  },
};
</script>

<template>
  <div>
    <pre class="p-0"><code ref="code" :class="data.lang == 'auto' ? '' : `language-${data.lang}`"><!--
    -->{{ data.text }}<!--
    --></code></pre>
    <p v-if="data.caption" class="mt-3 has-text-centered is-size-8">
      {{ data.caption }}
    </p>
  </div>
</template>
