<script>
// import VuePDFApp from '@shared/config/pdfViewer';

export default {
  // components: { VuePDFApp },
  static: {
    config: {
      toolbar: {
        toolbarViewerRight: {
          presentationMode: false,
          openFile: false,
          print: false,
          download: false,
          viewBookmark: false,
        },
      },
    },
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    encodedSrc() {
      return `${this.$env.apiDomain}/viewer/web/viewer.min.html?file=${encodeURIComponent(this.src)}`;
    },
  },
  created() {
    this.isLoading = true;
  },
};
</script>

<template>
  <div class="viewer is-relative w-full" :class="{'has-background-grey': isLoading}">
    <b-loading :active="isLoading" :is-full-page="false" />
    <iframe class="is-relative is-block w-full h-full" :src="encodedSrc" @load="isLoading = false" />
  </div>
</template>

<style lang="scss" scoped>
.viewer {
  z-index: 1;
  height: 520px;

  @include touch {
    height: 360px;
  }
}
</style>
