<script>
import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  data: () => ({
    content: '',
    html: '',
  }),
  computed: {
    sanitizedHTML() {
      return DOMPurify.sanitize(this.data.html);
    },
  },
};
</script>

<template>
  <div class="cbmarkdown">
    <div class="content mb-2" v-html="sanitizedHTML" />
  </div>
</template>

<style lang="scss" scoped>
.cbmarkdown {
  ::v-deep pre {
    padding: 0 !important;
  }
}
</style>
