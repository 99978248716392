<script>
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  components: { VueSimpleEmbed },
};
</script>

<template>
  <div>
    <VueSimpleEmbed
      class="has-text-centered"
      :attrs="{
        class: 'mw-full mx-auto',
        width: data.__isWide ? '100%' : '728',
      }"
      :extra-data="data"
      :parser="type"
      :url="data.url"
    >
      <template #caption>
        <p v-if="data.caption" class="mt-3 has-text-centered is-size-8">
          {{ data.caption }}
        </p>
      </template>
    </VueSimpleEmbed>
  </div>
</template>
