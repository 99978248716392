<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
};
</script>

<template>
  <component
    :is="`h${data.level}`"
    class="custom-title"
    :class="`has-text-weight-bold is-size-${data.level + 1}`"
  >
    {{ data.text }}
  </component>
</template>
