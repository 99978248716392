<script>
// import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    html() {
      return this.data.html;
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<template>
  <div class="cboecomp">
    <div class="iframe-container">
      <b-loading :active="!loaded" :is-full-page="false" />

      <iframe v-if="html" v-show="loaded" :srcdoc="html" class="iframe" @load="loaded = true" />
    </div>

    <p v-if="data.caption" class="has-text-centered is-size-8">
      {{ data.caption }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.cboecomp {
  ::v-deep iframe {
    width: 100%;
  }
}

.iframe-container {
  width: 100%;
  height: 800px;
}

.iframe {
  width: 100%;
  height: 100%;
}
</style>
