<script>
import transform from 'lodash/transform';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicTitle() {
      const [trainingItem] = transform(this.trainingItems, (result, item) => {
        if (item.children) {
          const found = item.children.find((child) => child.id === this.data.id);

          if (found) {
            result.push(found);
            return false;
          }
        }

        if (item.id === this.data.id) {
          result.push(item);
          return false;
        }

        return true;
      }, []);

      return trainingItem ? trainingItem.name : '(cette leçon n\'existe pas ou plus)';
    },
  },
};
</script>

<template>
  <div class="comptir box is-flex is-justify-content-space-between is-align-items-center">
    <h2>
      {{ data.title || dynamicTitle }}
    </h2>
    <b-button
      class="comptir_btn"
      tag="router-link"
      type="is-primary"
      :to="{
        name: 'mytrainingitem',
        params: {slug: training.slug, id: data.id}
      }">
      Voir la leçon
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.comptir {
  &_btn {
    margin-left: pxToRem(12);
  }

  @include mobile {
    & {
      flex-direction: column;

      &_btn {
        margin-left: 0;
        margin-top: pxToRem(12);
      }
    }
  }
}
</style>
