export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
    trainingItems: {
      type: Array,
      required: true,
    },
  },
};
