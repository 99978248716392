<script>
import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  data: () => ({
    html: '',
  }),
  watch: {
    'data.html': {
      immediate: true,
      handler: 'onChange',
    },
  },
  methods: {
    onChange(html) {
      if (!this.$el) {
        this.$nextTick(() => this.onChange(html));
        return;
      }

      const $html = this.$refs.html;

      if (this.html !== '' && $html) {
        this.resizeIframes($html.querySelectorAll('iframe'));
        return;
      }

      const ADD_ATTR = [
        'allow',
        'allowtransparency',
        'allowfullscreen',
        'mozallowfullscreen',
        'webkitallowfullscreen',
        'oallowfullscreen',
        'msallowfullscreen',
        'frameborder',
        'scrolling',
      ];
      html = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'], ADD_ATTR });
      const d = document.createElement('div');
      d.innerHTML = html;
      this.resizeIframes(d.querySelectorAll('iframe'));

      this.html = d.innerHTML;
    },
    resizeIframes($iframes) {
      const iframes = Array.from($iframes);
      iframes.forEach((iframe) => {
        const newW = this.$el.clientWidth || '100%';
        // const newH = (this.$el.clientWidth * (Number(iframe.height) / Number(iframe.width))) || '460';
        iframe.width = newW;
        // iframe.height = newH;
      });
    },
  },
  created() {
    const onResize = () => {
      // fullscreen iframes bug if recalculated
      if (this.html.includes('allowfullscreen')) {
        return;
      }

      this.onChange(this.html);
    };

    window.addEventListener('resize', onResize);
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', onResize);
    });
  },
};
</script>

<template>
  <div>
    <div ref="html" class="content mb-2" v-html="html" />
    <p v-if="data.caption" class="has-text-centered">
      {{ data.caption }}
    </p>
  </div>
</template>
