<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicData() {
      const path = this.data.path.startsWith('http')
        ? this.data.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return { path };
    },
  },
};
</script>

<template>
  <div class="compr box is-flex is-align-items-center is-justify-content-space-between">
    <p>
      {{ data.name }}
    </p>
    <div class="compr_btn flex-none">
      <a
        class="button is-primary is-custom"
        :href="dynamicData.path"
        target="_blank"
        rel="noreferrer noopener"
        download="download">
        Télécharger
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.compr {
  &_btn {
    margin-left: pxToRem(12);
  }

  @include mobile {
    & {
      flex-direction: column;

      &_btn {
        margin-left: 0;
        margin-top: pxToRem(12);
      }
    }
  }
}
</style>
