<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  mixins: [ContentBlockView],
  components: {
    VueSimpleEmbed,
  },
  computed: {
    dynamicData() {
      const path = this.data.path.startsWith('http')
        ? this.data.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return { path };
    },
    hasTimecode() {
      return (
        this.data
        && this.data.timecodes
        && this.data.timecodes.length
      );
    },
  },
  methods: {
    goToTimecode(timecode) {
      const { player } = this.$refs.player;
      const timeSplitted = timecode.time.split(':');
      const timeInSeconds = Math.min(
        player.duration - 1,
        parseInt(timeSplitted[0] * 60, 10) + parseInt(timeSplitted[1], 10),
      );

      if (!Number.isNaN(timeInSeconds)) {
        player.play();
        player.currentTime = timeInSeconds;
      }
    },
  },
};
</script>

<template>
  <div>
    <figure class="image has-text-centered overflow-visible">
      <template v-if="data.srcType != 'embed'">
        <vue-plyr ref="player">
          <video class="w-auto mw-full mx-auto" :src="dynamicData.path" />
        </vue-plyr>
        <figcaption v-if="data.caption" class="pt-3 has-text-centered is-size-8">
          {{ data.caption }}
        </figcaption>
        <div v-if="hasTimecode" class="box content has-text-justified mt-3">
          <h3 class="is-size-5-touch">
            Chapitrage de la vidéo
          </h3>
          <ul>
            <li v-for="(timecode, k) in data.timecodes" :key="k">
              {{ timecode.title }} :
              <a href="#" @click.prevent="goToTimecode(timecode)">
                {{ timecode.time }}
              </a>
            </li>
          </ul>
        </div>
      </template>
      <VueSimpleEmbed v-else
        class="has-text-centered"
        :attrs="{
          class: 'mw-full mx-auto',
          width: '728',
          height: '546'
        }"
        parser="plyr"
        :extra-data="data"
        :url="data.path"
      >
        <template v-if="data.caption" #caption>
          <figcaption class="pt-3 has-text-centered is-size-8">
            {{ data.caption }}
          </figcaption>
        </template>
      </VueSimpleEmbed>
    </figure>
  </div>
</template>
