<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  props: {
    isBase64: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dynamicData() {
      let { path } = this.data;

      if (!this.isBase64) {
        if (path.startsWith('http')) return { path };

        path = `${this.$env.apiResourcesURL}/${path}`;
      }

      return { path };
    },
  },
};
</script>

<template>
  <figure class="image has-text-centered">
    <img class="w-auto mw-full mx-auto" :src="dynamicData.path" :alt="data.caption">
    <figcaption v-if="data.caption" class="pt-3 has-text-centered is-size-8">
      {{ data.caption }}
    </figcaption>
  </figure>
</template>
