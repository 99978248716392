<script>
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicData() {
      const path = this.data.path.startsWith('http')
        ? this.data.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return { path };
    },
  },
};
</script>

<template>
  <figure class="image has-text-centered overflow-visible">
    <div class="box">
      <vue-plyr>
        <audio class="w-auto mw-full mx-auto" :src="dynamicData.path" />
      </vue-plyr>
    </div>
    <figcaption v-if="data.caption" class="has-text-centered is-size-8">
      {{ data.caption }}
    </figcaption>
  </figure>
</template>
