<script>
import { getLocation } from '@shared/services/Utils';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  computed: {
    dynamicHost() {
      return getLocation(this.data.url).hostname;
    },
  },
};
</script>

<template>
  <div class="mw-full">
    <div class="card">
      <div class="card-content columns">
        <div v-if="data.image" class="column is-4">
          <figure class="is-flex h-full">
            <a class="image h-full" :href="data.url" target="_blank" rel="noopener noreferrer">
              <img
                class="h-full ofit-cover bradius-8"
                :src="data.image" :alt="data.title || data.caption || data.url">
            </a>
          </figure>
        </div>
        <div class="column content has-text-left">
          <h4 class="is-size-7-touch">
            <a :href="data.url" target="_blank" rel="noopener noreferrer">
              {{ data.title }}
            </a>
          </h4>
          <p v-if="data.description" class="is-size-8-touch">
            {{ data.description }}
          </p>
          <p class="is-size-8-touch">
            <a class="is-flex is-size-8-touch" :href="data.url" target="_blank" rel="noopener noreferrer">
              <b-icon icon="link" />
              {{ dynamicHost }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <p v-if="data.caption" class="mt-8 has-text-centered is-size-8">
      {{ data.caption }}
    </p>
  </div>
</template>
