<script>
import DOMPurify from 'dompurify';
import ContentBlockView from '@shared/mixins/ContentBlockView';

export default {
  mixins: [ContentBlockView],
  data: () => ({ isOpen: false }),
  computed: {
    html() {
      return DOMPurify.sanitize(this.data.text);
    },
  },
};
</script>

<template>
  <div class="card">
    <header class="card-header">
      <a
        class="
          card-header-title is-flex is-align-items-center
          is-justify-content-space-between has-text-black
          has-text-weight-medium
        "
        @click.prevent="isOpen = !isOpen"
      >
        <b-tooltip position="is-top">
          <template #content>
            <template v-if="isOpen">
              Cliquez pour cacher
            </template>
            <template v-else>
              Cliquez pour afficher
            </template>
          </template>
          {{ data.title }}
        </b-tooltip>
        <b-icon
          class="ml-3"
          :icon="isOpen ? 'chevron-down' : 'chevron-right'"
        />
      </a>
    </header>
    <div v-show="isOpen" class="card-content">
      <div class="content break-words" v-html="html" />
    </div>
  </div>
</template>
